<template>
  <div>
    <img class="banner" src="../../assets/img/winner/banner.webp" alt="财智大赢家">
    <div class="circle-line__container">
      <img class="circle-line" src="../../assets/img/winner/circle-line.png" alt="">
      <img class="circle-line" src="../../assets/img/winner/circle-line.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "WinnerBanner"
  }
</script>

<style lang="scss" scoped>
  // 头图
  .banner {
    display: block;
    width: 100%;
  }
  // 两个圆圈
   .circle-line__container {
     position: relative;
     height: 0;
   }

  .circle-line {
    &:first-child {
       height: 360px;
       width: 360px;
       position: absolute;
       left: 300px;
       top: -180px;
     }
    &:last-child {
       height: 250px;
       width: 250px;
       position: absolute;
       right: 340px;
     }
  }
</style>