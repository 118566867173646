<template>
  <div class="winner">
    <winner-banner/>
    <title-comp title-cn="财智大赢家系统" title-en="Super FQ Winner System" style="margin: 100px 0;"/>
    <p class="context">
      财智大赢家系统利用体验式的课程内容，突出情景转化知识的运用能力。通过系统性学习、全面的课程内容、易懂易明的情景、导师的辅导、家长的参与、利用
      记账等作业帮助小孩建立良好的习惯。让孩子学会用财商解决习惯问题，用技能解决生活问题。
    </p>

    <img src="../../assets/img/winner/财商少年官网-财智大赢家系统01_03.jpg" class="color-box__container">
<!--    <div class="color-box__container">-->
<!--      <div class="color-box__mix">-->
<!--        <img class="color-box__mix-top" src="../../assets/img/winner/card-winner.png" alt="财智大赢家">-->
<!--        <div class="color-box__mix-bottom">-->
<!--          <div class="color-box color-box&#45;&#45;blue"></div>-->
<!--          <div class="color-box color-box&#45;&#45;green"></div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="color-box color-box&#45;&#45;red"></div>-->
<!--      <div class="color-box color-box&#45;&#45;yellow"></div>-->
<!--    </div>-->
    <title-comp title-cn="核心理念" title-en="Core Idea" style="margin-top: 110px"></title-comp>
    <p class="context">
      财智大赢家系统利用体验式的课程内容，突出情景转化知识的运用能力。通过系统性学习、全面的课程内容、易懂易明的情景、导师的辅导、家长的参与、利用
      记账等作业帮助小孩建立良好的习惯。让孩子学会用财商解决习惯问题，用技能解决生活问题。
    </p>

    <div class="card card--red">
      <div class="card__left">
        <img src="../../assets/img/winner/photo-red.png" alt="">
      </div>
      <img class="card__triangle" src="../../assets/img/winner/triangle-grey.png"/>
      <div class="card__right card__right--1">
        <p class="card__title">用财商解决习惯问题，用技能解决生活问题</p>
        <div class="card__line"></div>
        <p class="card__content">把知识转化为技能，让孩子受到良性的影响，解决过度消费问题，学会控制支出，学会记账等，形成良好习惯。</p>
      </div>
    </div>

    <div class="card card--green">
      <div class="card__left">
        <img src="../../assets/img/winner/photo-green.png" alt="">
      </div>
      <img class="card__triangle" src="../../assets/img/winner/triangle-grey.png"/>
      <div class="card__right card__right--1">
        <p class="card__title">掌握财商智慧，成就美好未来</p>
        <div class="card__line"></div>
        <p class="card__content">通过学习丰富完善的财智大赢家系统课程，让您及您的孩子掌握财商智慧，成就美好未来。</p>
      </div>
    </div>

    <div class="card card--yellow">
      <div class="card__left">
        <img src="../../assets/img/winner/photo-blue.png" alt="">
      </div>
      <img class="card__triangle" src="../../assets/img/winner/triangle-grey.png"/>
      <div class="card__right card__right--1">
        <p class="card__title">培养财商思维，养成良好习惯</p>
        <div class="card__line"></div>
        <p class="card__content">财智大赢家系统完善的教学体系，更好地培养孩子的财商思维，从而养成良好的习惯。</p>
      </div>
    </div>

    <title-comp title-cn="教学体系优势" title-en="Advantages Of Teaching System" style="margin: 100px 0"></title-comp>
    <p class="context">
      财智大赢家系统利用体验式的课程内容，突出情景转化知识的运用能力。通过系统性学习、全面的课程内容、易懂易明的情景、导师的辅导、家长的参与、利用
      记账等作业帮助小孩建立良好的习惯。让孩子学会用财商解决习惯问题，用技能解决生活问题。
    </p>

    <div class="duoduo">
      <div class="duoduo__left">
        <div class="duoduo__text">课程内容丰富全面</div>
        <div class="duoduo__text">导师专业辅导教学</div>
        <div class="duoduo__text">品牌独创专利教具</div>
      </div>
      <img src="../../assets/img/winner/duoduo.png" alt="多多">
      <div class="duoduo__right">
        <div class="duoduo__text">教学情景易明易懂</div>
        <div class="duoduo__text">家长陪伴亲子互动</div>
        <div class="duoduo__text">打造多元教学体系</div>
      </div>
    </div>

    <div class="bg"></div>
  </div>
</template>

<script>
  import TitleComp from "@/views/main/childComp/TitleComp";
  import WinnerBanner from "@/components/common/WinnerBanner";
  export default {
    name: "Winner",
    components: {
      TitleComp,
      WinnerBanner
    }
  }
</script>

<style lang="scss" scoped>
  $red: #e40073;
  $green: #95c51b;
  $yellow: #ffe100;
  $blue: #00abeb;

  .winner {
    position: relative;
  }

  // 主要文字
  .context {
    width: 1320px;
    margin: 100px auto;
    font-size: 23px;
    color: #202730;
    text-align: center;
  }

  // 四种颜色的盒子
  .color-box__container {
    width: 1320px;
    height: 660px;
    margin: 0 auto;
    display: flex;
  }
  .color-box__mix {
    flex: 660px;
  }
  .color-box__mix-top {
    display: block;
    height: 330px;
    width: 660px;
  }
  .color-box__mix-bottom {
    display: flex;
    height: 330px;
    width: 660px;
  }
  .color-box {
    flex: 330px;
  }
  .color-box--red {
    background: $red;
  }
  .color-box--yellow {
    background: $yellow;
  }
  .color-box--blue {
    background: $blue;
  }
  .color-box--green {
    background: $green;
  }

  // 三张不同颜色的卡片
  .card {
    width: 1320px;
    height: 300px;
    margin: 0 auto 20px;
    position: relative;
    display: flex;
    z-index: 2;
  }
  .card__left {
    width: 660px;
    height: 300px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .card__triangle {
    height: 60px;
    width: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(660px - 25px);
  }
  .card__right {
    width: 660px;
    height: 300px;
    box-sizing: border-box;
    padding-left: 26px;
    background-color: #f1f1f1;
    background-size: 100%;
  }
  .card__title {
    font-size: 32px;
    padding-top: 16px;
  }
  .card__line {
    height: 3px;
    width: 463px;
  }
  .card__content {
    font-size: 21px;
    padding-top: 16px;
    width: 463px;
  }
  // 红色卡片
  .card--red {
    .card__left {
      background-color: $red;
    }
    .card__right {
      background-image: url("../../assets/img/winner/number-1.png");
    }
    .card__title {
      color: $red;
    }
    .card__line {
      background-color: $red;
    }
  }
  // 绿色卡片
  .card--green {
    .card__left {
      background-color: $green;
    }
    .card__right {
      background-image: url("../../assets/img/winner/number-2.png");
    }
    .card__title {
      color: $green;
    }
    .card__line {
      background-color: $green;
    }
  }
  // 黄色卡片
  .card--yellow {
    .card__left {
      background-color: $yellow;
    }
    .card__right {
      background-image: url("../../assets/img/winner/number-3.png");
    }
    .card__title {
      color: $blue;
    }
    .card__line {
      background-color: $blue;
    }
  }

  // 多多
  .duoduo {
    img {
      width: 360px;
      height: 410px;
    }
    display: flex;
    width: 1320px;
    margin: 0 auto;
    padding-bottom: 155px;
    justify-content: center;
    font-size: 32px;
    color: #231815;
    position: relative;
    z-index: 2;
  }
  .duoduo__left,
  .duoduo__right {
    margin-top: 30px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .duoduo__text {
    display: flex;
    align-items: center;
    position: relative;
  }
  .duoduo__left {
    .duoduo__text {
      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 60px;
        margin-left: 20px;
        background-size: 100%;
      }
      &:nth-child(1) {
        left: 20px;
        &:after {
          background-image: url("../../assets/img/winner/circle-blue.png");
        }
      }
      &:nth-child(2) {
        right: 20px;
        &:after {
          background-image: url("../../assets/img/winner/circle-green.png");
        }
      }
      &:nth-child(3) {
        left: 20px;
        &:after {
          background-image: url("../../assets/img/winner/circle-red.png");
        }
      }
    }
  }
  .duoduo__right {
    .duoduo__text {
      &:before {
        content: '';
        display: block;
        width: 60px;
        height: 60px;
        margin-right: 20px;
        background-size: 100%;
      }
      &:nth-child(1) {
        right: 20px;
        &:before {
          background-image: url("../../assets/img/winner/circle-blue.png");
        }
      }
      &:nth-child(2) {
        left: 20px;
        &:before {
          background-image: url("../../assets/img/winner/circle-green.png");
        }
      }
      &:nth-child(3) {
        right: 20px;
        &:before {
          background-image: url("../../assets/img/winner/circle-red.png");
        }
      }
    }
  }

  // 背景图
  .bg {
    height: 1086px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-image: url("../../assets/img/winner/bg.png");
    background-position: center;
    background-size: cover;
    z-index: 1;
  }
</style>